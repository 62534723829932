<div class="container">
  <div class="container-width">
    <!-- TITULO -->
    <div class="d-flex flex-row align-items-baseline" style="margin-top: -10px;">
      <div class="flex-grow-1">
        <h4>Relatórios</h4>
      </div>
    </div>

    <div id="inputsReport">
      <div class="mt-5" style="width: 760px;">
        <div style="height: 15px;"></div>
        <p-fieldset legend="&nbsp;Período&nbsp;">
          <div style="margin-bottom: 3px;">
            <div class="d-flex flex-rom">
              <div class="d-flex flex-row flex-wrap gap-4 me-3 periodo">
                <div *ngFor="let check of daysCheck">
                  <p-radioButton
                    [ngClass]="{'ms-4': check.key == 'custom'}"
                    [disabled]="processamentoRelatorio"
                    (onClick)="setInputDate(check.key)"
                    [inputId]="check.key"
                    name="daysCheck"
                    [value]="check"
                    [(ngModel)]="selectedCheckDate">
                  </p-radioButton>
                  <label [for]="check.key" style="margin-left:5px">{{ check.label }}</label>
                </div>
              </div>
              <div class="me-4 d-flex flex-column" style="width: 150px;">
                <label for="dataInicio">Data inicial</label>
                <p-calendar
                  [disabled]="customCheck() || processamentoRelatorio"
                  aria-label="Date"
                  [(ngModel)]="dataInicio"
                  [showIcon]="true"
                  inputId="dataInicio"
                  dateFormat="dd/mm/yy">
                </p-calendar>
              </div>
              <div class="d-flex flex-column" style="width: 150px;">
                <label for="dataFim">Data fim</label>
                <p-calendar
                  [disabled]="customCheck() || processamentoRelatorio"
                  [(ngModel)]="dataFim"
                  [showIcon]="true"
                  inputId="dataFim"
                  dateFormat="dd/mm/yy">
                </p-calendar>
              </div>
            </div>
          </div>
        </p-fieldset>
      </div>
      <div style="height: 15px;"></div>
      <div class="d-flex flex-wrap justify-content-lg-start">
        <div class="me-3 mt-5">
          <!-- NFe -->
          <p-fieldset legend="&nbsp;NF-e&nbsp;">
            <div style="margin-bottom: 14px;">
              <div class="d-flex align-items-baseline gap-3">
                <div>
                  <p-checkbox
                    [disabled]="nfe.checkEmitidas"
                    [(ngModel)]="nfe.checkRecebidas"
                    [binary]="true"
                    inputId="nfeRecebidas"
                    label="Recebidas">
                  </p-checkbox>
                </div>
              </div>
            </div>
            <p-divider></p-divider>
            <p>Intervalo de notas</p>
            <div class="d-flex flex-rom">
              <div class="me-3 d-flex flex-column">
                <label for="numNfInicio">Nota a partir de</label>
                <input pInputText id="numNfInicio" [(ngModel)]="nfe.numNotaDe" [pKeyFilter]="'int'" style="width: 120px" [disabled]="processamentoRelatorio"/>
              </div>
              <div class="d-flex flex-column">
                <label for="numNfFim">Nota até</label>
                <input pInputText id="numNfFim" [(ngModel)]="nfe.numNotaAte" [pKeyFilter]="'int'" style="width: 120px" [disabled]="processamentoRelatorio"/>
              </div>
            </div>
          </p-fieldset>
        </div>
        <div class="me-3 mt-5">
          <!-- CTe -->
          <p-fieldset legend="&nbsp;CT-e&nbsp;">
            <div style="margin-bottom: 14px;">
              <div class="d-flex align-items-baseline gap-3">
                <div>
                  <p-checkbox
                    [disabled]="cte.checkEmitidas"
                    [(ngModel)]="cte.checkRecebidas"
                    [binary]="true"
                    inputId="cteRecebidas"
                    label="Recebidas">
                  </p-checkbox>
                </div>
              </div>
            </div>
            <p-divider></p-divider>
            <p>Intervalo de notas</p>
            <div class="d-flex flex-rom">
              <div class="me-3 d-flex flex-column">
                <label for="numNfInicio">Nota a partir de</label>
                <input pInputText id="numNfInicio" [(ngModel)]="cte.numNotaDe" [pKeyFilter]="'int'" style="width: 120px" [disabled]="processamentoRelatorio"/>
              </div>
              <div class="d-flex flex-column">
                <label for="numNfFim">Nota até</label>
                <input pInputText id="numNfFim" [(ngModel)]="cte.numNotaAte" [pKeyFilter]="'int'" style="width: 120px" [disabled]="processamentoRelatorio"/>
              </div>
            </div>
          </p-fieldset>
        </div>
        <div class="me-3 mt-5">
          <!-- CFe -->
          <p-fieldset legend="&nbsp;CF-e&nbsp;">
            <div style="margin-bottom: 14px;">
              <div class="d-flex align-items-baseline gap-3">
                <div>
                  <p-checkbox
                    [disabled]="cfe.checkEmitidas"
                    [(ngModel)]="cfe.checkRecebidas"
                    [binary]="true"
                    inputId="cfeRecebidas"
                    label="Recebidas">
                  </p-checkbox>
                </div>
              </div>
            </div>
            <p-divider></p-divider>
            <p>Intervalo de notas</p>
            <div class="d-flex flex-rom">
              <div class="me-3 d-flex flex-column">
                <label for="numNfInicio">Nota a partir de</label>
                <input pInputText id="numNfInicio" [(ngModel)]="cfe.numNotaDe" [pKeyFilter]="'int'" style="width: 120px" [disabled]="processamentoRelatorio"/>
              </div>
              <div class="d-flex flex-column">
                <label for="numNfFim">Nota até</label>
                <input pInputText id="numNfFim" [(ngModel)]="cfe.numNotaAte" [pKeyFilter]="'int'" style="width: 120px" [disabled]="processamentoRelatorio"/>
              </div>
            </div>
          </p-fieldset>
        </div>

        <div class="me-3 mt-5">
          <!-- NFS-e Nacional -->
          <p-fieldset legend="&nbsp;NFS-e Nacional&nbsp;">
            <div style="margin-bottom: 14px;">
              <div class="d-flex align-items-baseline gap-3">
                <div>
                  <p-checkbox
                    [disabled]="portalNacional.checkEmitidas"
                    [(ngModel)]="portalNacional.checkRecebidas"
                    [binary]="true"
                    inputId="portalNacionalRecebidas"
                    label="Recebidas">
                  </p-checkbox>
                </div>
              </div>
            </div>
            <p-divider></p-divider>
            <p>Intervalo de notas</p>
            <div class="d-flex flex-rom">
              <div class="me-3 d-flex flex-column">
                <label for="numNfInicio">Nota a partir de</label>
                <input pInputText id="numNfInicio" [(ngModel)]="portalNacional.numNotaDe" [pKeyFilter]="'int'" style="width: 120px" [disabled]="processamentoRelatorio"/>
              </div>
              <div class="d-flex flex-column">
                <label for="numNfFim">Nota até</label>
                <input pInputText id="numNfFim" [(ngModel)]="portalNacional.numNotaAte" [pKeyFilter]="'int'" style="width: 120px" [disabled]="processamentoRelatorio"/>
              </div>
            </div>
          </p-fieldset>
        </div>

        <div class="me-3 mt-5">
          <!-- NFSe -->
          <p-fieldset legend="&nbsp;NFS-e&nbsp;">
            <div style="margin-bottom: 14px;">
              <div class="d-flex align-items-baseline gap-3">
                <div>
                  <p-checkbox
                    [(ngModel)]="nfse.checkRecebidas"
                    [binary]="true"
                    inputId="nfseRecebidas"
                    (onChange)="nfse.checkRecebidas ? nfse.checkEmitidas = false : nfse.checkRecebidas = false"
                    label="Tomadas">
                  </p-checkbox>
                </div>
                <div class="ms-4">
                  <p-checkbox
                    [(ngModel)]="nfse.checkEmitidas"
                    [binary]="true"
                    inputId="nfseEmitidas"
                    (onChange)="nfse.checkEmitidas ? nfse.checkRecebidas = false : nfse.checkEmitidas = false"
                    label="Prestadas">
                  </p-checkbox>
                </div>
              </div>
              <div>
                <div class="field me-4">
                  <label class="block">&nbsp;</label>
                  <p-dropdown
                    [options]="municipiosContratados"
                    [autoDisplayFirst]="true"
                    [editable]="false"
                    [(ngModel)]="selectedMunicipio"
                    [ngModelOptions]="{standalone: true}"
                    (onChange)="onChangeMunicipio($event)"
                    optionLabel="municipio"
                    placeholder="Selecione o município"
                    [style]="{'width' : '280px'}"
                    [disabled]="selectedEmpresa == null || processamentoRelatorio">
                  </p-dropdown>
                </div>
              </div>
            </div>
            <p-divider></p-divider>
            <p>Intervalo de notas</p>
            <div class="d-flex flex-rom">
              <div class="me-3 d-flex flex-column">
                <label for="numNfInicio">Nota a partir de</label>
                <input [(ngModel)]="nfse.numNotaDe" pInputText id="numNfInicio" value="" [pKeyFilter]="'int'" style="width: 120px" [disabled]="processamentoRelatorio"/>
              </div>
              <div class="d-flex flex-column">
                <label for="numNfFim">Nota até</label>
                <input [(ngModel)]="nfse.numNotaAte" pInputText id="numNfFim" value="" [pKeyFilter]="'int'" style="width: 120px" [disabled]="processamentoRelatorio"/>
              </div>
            </div>
          </p-fieldset>
        </div>
      </div>
    </div>

    <div class="d-flex flex-row mt-4">
      <p-button label="Baixar relatório em planilha" (onClick)="baixarRelatorioPlanilha()" [disabled]="processamentoRelatorio"></p-button>
      <div *ngIf="processamentoRelatorio" class="ms-3">
        <p-progressSpinner styleClass="w-3rem h-3rem" strokeWidth="6" animationDuration=".5s"></p-progressSpinner>
      </div>
    </div>
  </div>
</div>
