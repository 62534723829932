import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

const API_DANFSE_PADRAO_NACIONAL = environment.API_MAIN + '/nfse-nacional/danfse/';

@Injectable({
  providedIn: 'root'
})
export class PdfService {

  constructor(private http: HttpClient) {}

    getDanfse(idEmpresa: string, chaveAcesso: string): Observable<Blob> {
      return this.http.get(`${API_DANFSE_PADRAO_NACIONAL}${idEmpresa}/${chaveAcesso}`, { responseType: 'blob' })
        .pipe(
          catchError((error: HttpErrorResponse) => {
            let errorMessage = 'Erro desconhecido';

            if (error.status === 511) {
              errorMessage = 'Certificado vencido ou inválido.';
            } else if (error.status === 404) {
              errorMessage = 'Arquivo não encontrado.';
            } else if (error.status === 500) {
              errorMessage = 'Erro interno no servidor.';
            }

            return throwError(() => new Error(errorMessage));
          })
        );
    }
}
