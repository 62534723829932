import { GlobalEventService } from 'src/shared/global-event.service.ts.service';
import { AuthService } from '../../services/auth.service';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { validator } from 'cpf-cnpj-validator';
import { GrupoMunicipios } from "src/model/grupo-municipios.model";
import { MunicipiosNfseService } from "src/services/municipios-nfse.service";
import { ListboxDoubleClickEvent } from "primeng/listbox";
import { DadosMunicipioNfseModel } from "src/model/dados-municipio-nfse.model";
import { TabView } from 'primeng/tabview';
import { MessageService } from 'primeng/api';
import { Empresa } from 'src/model/empresa.model';
import { NfseMunicipio } from 'src/model/nfse-municipios';
import { Cliente } from 'src/model/cliente.model';
import { FileUpload } from 'primeng/fileupload';
import { EmpresaService } from 'src/services/empresas.service';
import { CertificadoEmpresa } from 'src/model/certificado-empresa.model';
import { Prestador } from 'src/model/prestador.model';
import { HttpStatusCode } from '@angular/common/http';
import { Endereco } from 'src/model/endereco.model';
import { DadosEmissaoNf } from 'src/model/dados-emissao-nf.model';

@Component({
  selector: 'app-form-cadastro-empresa',
  templateUrl: './form-cadastro-empresa.component.html',
  styleUrls: ['./form-cadastro-empresa.component.scss']
})
export class FormCadastroEmpresaComponent implements OnInit, OnChanges {

  @ViewChild('tabView') tabView!: TabView;
  @ViewChild('fileUpload') fileUpload!: FileUpload;

  @Input()
  get empresa(): any {
    return this._empresa
  }
  set empresa(value: any) {
    this.loadPage = false;

    if(value) {
      this._empresa = this.clone(value);

      this.empresaNova = this._empresa.id == undefined || this._empresa.id == null;
      this.exibeCampoAtiva = this.empresaNova || this.isLoggedAdmin;
      this.possuiPendencias = false;
      this.selectedTab = 0;

      if (this.empresaNova) {
        this._empresa.ativa = true;
        this._empresa.modulos.nfseModulo.nfseMunicipios = [];
        this._empresa.endereco = new Endereco();
        this._empresa.dadosEmissaoNf = new DadosEmissaoNf();
      } else {
        if (this._empresa.dadosEmissaoNf === null) {
          this._empresa.dadosEmissaoNf = new DadosEmissaoNf();
        }
        if (this._empresa.endereco === null) {
          this._empresa.endereco = new Endereco();
        }
      }

      this.dataRetroativaNFSe = this._empresa.modulos.nfseModulo.dataRetroativaNFSe;
      if (this.dataRetroativaNFSe) {
        this._empresa.modulos.nfseModulo.dataRetroativaNFSe = new Date(this.dataRetroativaNFSe);
      }

      this._empresaAux = this.clone(this._empresa);
    }

    setTimeout(() => this.loadPage = true);
  }

  @Input() showBotaoCancelar = true;
  @Input() somenteVisualizar = false;

  @Output() empresaChange = new EventEmitter<any>();
  @Output() onClickCancelar = new EventEmitter();
  @Output() onClickSalvar = new EventEmitter<any>();

  clienteLogado: Cliente | null;
  isLoggedAdmin = false;

  _empresa: Empresa = new Empresa();
  _empresaAux: Empresa = new Empresa();

  loadPage = true;

  subscriptionEmpresaChange: any;

  todosMunicipios: DadosMunicipioNfseModel[] = [];

  cnpjNovo: Boolean = true;
  cnpjValido = false;
  dataRetroativaNFSe: Date | undefined;

  grupoMunicipios: GrupoMunicipios[] = [];
  municipiosSelecionados: DadosMunicipioNfseModel[] = [];
  municipioSelecionado: DadosMunicipioNfseModel | undefined;

  selectedTipo: {name: string, key: number} = {name: 'Nenhum',  key: 99};
  xmlUploadedFiles?: File[];

  estados = [
    { name: 'SP', code: 35 },
    { name: 'AC', code: 12 },
    { name: 'AL', code: 27 },
    { name: 'AP', code: 16 },
    { name: 'AM', code: 13 },
    { name: 'BA', code: 29 },
    { name: 'CE', code: 23 },
    { name: 'DF', code: 53 },
    { name: 'ES', code: 32 },
    { name: 'GO', code: 52 },
    { name: 'MA', code: 21 },
    { name: 'MT', code: 51 },
    { name: 'MS', code: 50 },
    { name: 'MG', code: 31 },
    { name: 'PA', code: 15 },
    { name: 'PB', code: 25 },
    { name: 'PR', code: 41 },
    { name: 'PE', code: 26 },
    { name: 'PI', code: 22 },
    { name: 'RJ', code: 33 },
    { name: 'RN', code: 24 },
    { name: 'RS', code: 43 },
    { name: 'RO', code: 11 },
    { name: 'RR', code: 14 },
    { name: 'SC', code: 42 },
    { name: 'SE', code: 28 },
    { name: 'TO', code: 17 }
  ];

  selectedRadio: string = '';

  tipoDocumentoCheck: any[] = [
    { name: 'CT-e',  key: 1 },
    { name: 'NF-E',  key: 2 },
    { name: 'NFS-E', key: 3 },
    { name: 'CF-e',  key: 4 }
  ];

  processamentoRelatorio = false;

  showModalPreencherDados = false;
  munSelecionadoModal: number | undefined;
  login: String | undefined;
  senha: String | undefined;
  inscricaoMunicipal: String | undefined;
  secretToken: String | undefined;
  prestadores: Prestador[] = [];

  exibeCampoAtiva: boolean = false;

  possuiPendencias = false;
  empresaNova = false;
  cnpjErroMensagem = "CNPJ inválido"

  selectedTab: number = 0;

  isValidInscricaoMunicipal = true;

  _chaveAcesso!: String;

  constructor(
    private authService: AuthService,
    private municipiosNfseService: MunicipiosNfseService,
    private messageService: MessageService,
    private empresaService: EmpresaService,
    private globalEventService: GlobalEventService
  ) {
    this.clienteLogado = authService.getUsuario();
  }

  ngOnInit(): void {
    this.isLoggedAdmin = this.authService.getUsuario()?.login.toUpperCase() === "ADMIN";

    this.municipiosNfseService.getMunicipios().then(muns => {
      this.todosMunicipios = muns;
      this.municipiosNfseService.getGrupoMunicipios().then(result => {
        this.grupoMunicipios = result;
      });
    });
  }

  ngOnChanges(_changes: SimpleChanges) {
    this.municipiosSelecionados = [];
    if (this._empresa.modulos.nfseModulo.nfseMunicipios && this._empresa.modulos.nfseModulo.nfseMunicipios.length > 0) {
      this._empresa.modulos.nfseModulo.nfseMunicipios.forEach(async mun => {
        if (mun.codigoMun) {
          await this.municipiosNfseService.getMunicipioPeloCodigo(mun.codigoMun).then(result => {
            const municipioEmpresa = this.getNfseMunicipio(result.codigo);
            result.emiteNfseManual = municipioEmpresa!.emiteNfseManualmente;
            result.temTomada = municipioEmpresa!.temTomada;
            result.temPrestada = municipioEmpresa!.temPrestada;
            this.municipiosSelecionados.push(result);
            this.verificarPendencia(result);
          });
        }
      });
    }
    setTimeout(() => {
      this.limparErros();
      if (this.fileUpload) {
        this.fileUpload.clear();
      }
    });
  }

  async adicionarMunicipio(event: ListboxDoubleClickEvent) {
    const codMun = event.option.value;
    if (!this.municipiosSelecionados.find(t => t.codigo == codMun)) {
      await this.municipiosNfseService.getMunicipioPeloCodigo(codMun).then(result => {
        result.temTomada = true;
        result.temPrestada = true;
        this.municipiosSelecionados.push(result);
        let municipioNfse: NfseMunicipio = new NfseMunicipio();
        municipioNfse.codigoMun = codMun;
        municipioNfse.temPrestada = true;
        municipioNfse.temTomada = true;
        if (!this._empresa.modulos.nfseModulo.nfseMunicipios) {
          this._empresa.modulos.nfseModulo.nfseMunicipios = [];
        }
        this._empresa.modulos.nfseModulo.nfseMunicipios.push(municipioNfse);
        this.verificarPendencias();
      });
    }
  }

  removeMunicipio(mun: DadosMunicipioNfseModel) {
    this.municipiosSelecionados = this.municipiosSelecionados.filter(t => t.codigo != mun.codigo);
    this._empresa.modulos.nfseModulo.nfseMunicipios = this._empresa.modulos.nfseModulo.nfseMunicipios.filter(t => t.codigoMun != mun.codigo);
    this.verificarPendencias();
  }

  private verificarPendencias() {
    this.possuiPendencias = false;
    this.municipiosSelecionados.forEach(munSel => {
      if (munSel) {
        this.verificarPendencia(munSel);
      }
    });
  }

  private verificarPendencia(munSel: DadosMunicipioNfseModel) {
    const munEmp = this.getNfseMunicipio(munSel.codigo);

    var pendenciaLoginSite = false;
    var pendenciaInscricaoMunicipal = false;
    var pendenciaSecretToken = false;
    var pendenciaListaPrestadores = false;

    if (munSel?.requerLoginSite) {
      pendenciaLoginSite = !(munEmp?.login && munEmp.senha);
    }
    if (munSel?.requerInscricaoMunicipal) {
      pendenciaInscricaoMunicipal = !munEmp?.inscricaoMunicipal;
    }
    if (munSel?.requerSecretToken) {
      pendenciaSecretToken = !munEmp?.secretToken;
    }
    if (munSel?.requerListaPrestadores) {
      pendenciaListaPrestadores = !(munEmp?.prestadores?.length! > 0);
    }

    munSel.pendencia = pendenciaLoginSite || pendenciaInscricaoMunicipal || pendenciaListaPrestadores || pendenciaSecretToken;
    if (!this.possuiPendencias) {
      this.possuiPendencias = munSel.pendencia;
    }
  }

  requerAlgumDadoAdicional(mun: DadosMunicipioNfseModel) : Boolean {
    return mun?.requerLoginSite || mun?.requerInscricaoMunicipal || mun?.diferenciaTipoEmissao || mun?.requerListaPrestadores || mun?.requerSecretToken;
  }

  onDateChange() {
    if (this._empresa && this._empresa.modulos) {
      console.debug("Data: ", this._empresa.modulos.nfseModulo.dataRetroativaNFSe);
      console.debug("Data de registro: ", this._empresa.dataRegistro);
    }
  }

  clearDate() {
    if (this._empresa && this._empresa.modulos) {
      this._empresa.modulos.nfseModulo.dataRetroativaNFSe = undefined;
      console.debug("Data: ", this._empresa.modulos.nfseModulo.dataRetroativaNFSe);
    }
  }

  public openModalCadastroDadosComplementares(mun: DadosMunicipioNfseModel) {
    this.openModalPreencheDados(mun)
  }

  private openModalPreencheDados(mun: DadosMunicipioNfseModel) {
    this.showModalPreencherDados = true;
    this.municipioSelecionado = mun;

    this.isValidInscricaoMunicipal = true;
    this.munSelecionadoModal = mun.codigo;

    const munSel = this.getNfseMunicipio(mun.codigo);

    this.login = munSel?.login;
    this.senha = munSel?.senha;
    this.inscricaoMunicipal = munSel?.inscricaoMunicipal;
    this.secretToken = munSel?.secretToken;
    if (mun.requerListaPrestadores) {
      if (munSel.prestadores && munSel.prestadores.length > 0) {
        this.prestadores = munSel.prestadores;
      } else {
        this.prestadores = [];
        this.prestadores.push(new Prestador());
      }
    }
  }

  validateInscricaoMunicipal(input: any){
    if((/^[^\W_]+$/).test(input)){
      this.isValidInscricaoMunicipal = true;
    } else {
      this.isValidInscricaoMunicipal = false;
    }
  }

  onCheckboxTipoEmissaoChange(mun: DadosMunicipioNfseModel) {
    let municipioEmpresa = this._empresa.modulos.nfseModulo.nfseMunicipios.filter(municipio => mun.codigo == municipio.codigoMun)[0];
    municipioEmpresa.emiteNfseManualmente = mun.emiteNfseManual;
    this._empresa.modulos.nfseModulo.nfseMunicipios.filter(municipio => mun.codigo == municipio.codigoMun)[0] = municipioEmpresa;
  }

  onCheckboxTemTomadas(mun: DadosMunicipioNfseModel) {
    if (!mun.temPrestada && !mun.temTomada) {
      mun.temPrestada = true;
    }
    let municipioEmpresa = this.getNfseMunicipio(mun.codigo);
    municipioEmpresa!.temPrestada = mun.temPrestada;
    municipioEmpresa!.temTomada = mun.temTomada;
  }

  onCheckboxTemPrestadas(mun: DadosMunicipioNfseModel) {
    if (!mun.temTomada && !mun.temPrestada) {
      mun.temTomada = true;
    }
    let municipioEmpresa = this.getNfseMunicipio(mun.codigo);
    municipioEmpresa!.temPrestada = mun.temPrestada;
    municipioEmpresa!.temTomada = mun.temTomada;
  }

  private closeModalPreencherDados() {
    this.showModalPreencherDados = false;
    this.munSelecionadoModal = undefined;
  }

  atualizaEmpresaDadosLoginNfse() {
    let mun = this.getNfseMunicipio(this.munSelecionadoModal!);

    const removerPrestadoresVazios = this.prestadores.filter(p => (p.cnpj == undefined || p.cnpj.trim() == '') && (p.inscricaoMunicipal == undefined || p.inscricaoMunicipal?.trim() == ''));
    removerPrestadoresVazios.forEach(rem => this.prestadores.splice(this.prestadores.findIndex(p => p == rem), 1));

    if (mun) {
      mun.login = this.login?.trim();
      mun.senha = this.senha?.trim();
      mun.inscricaoMunicipal = this.inscricaoMunicipal?.trim();
      mun.secretToken = this.secretToken?.trim();
      mun.prestadores = this.prestadores;
      this.verificarPendencias();
    }
    this.closeModalPreencherDados();
  }

  validaPreenchimentoDados(): boolean {
    let inscricaoMunOk = true;
    let prestadoresOk = true;
    let secretTokenOk = true;

    if (this.municipioSelecionado?.requerInscricaoMunicipal) {
      inscricaoMunOk = this.isValidInscricaoMunicipal && (this.inscricaoMunicipal != undefined && this.inscricaoMunicipal.trim() != '');
    }

    if (this.municipioSelecionado?.requerSecretToken) {
      secretTokenOk = this.isValidInscricaoMunicipal && (this.secretToken != undefined && this.secretToken.trim() != '');
    }

    if (this.municipioSelecionado?.requerListaPrestadores) {
      if (this.prestadores.length > 0) {
        const temPeloMenosUmPreenchido = this.prestadores.some(p => {
          const cnpjOk = p.cnpj != undefined && p.cnpj.trim() != '';
          const inscriMunOk = p.inscricaoMunicipal != undefined && p.inscricaoMunicipal.trim() != '';
          return cnpjOk || inscriMunOk;
        });
        const cnpjPreenchidosCorretamente =  this.prestadores.every(p => p.cnpj == undefined || this.validaCnpjPrestador(p.cnpj));
        prestadoresOk = temPeloMenosUmPreenchido && cnpjPreenchidosCorretamente;
      } else {
        prestadoresOk = false;
      }
    }

    return inscricaoMunOk && prestadoresOk && secretTokenOk;
  }

  private getNfseMunicipio(mun: number): NfseMunicipio {
    return this._empresa.modulos.nfseModulo.nfseMunicipios.find(munEmp => munEmp.codigoMun == mun)!;
  }

  onBlurCnpj(event: any) {
    var value: String = event.target.value;
    if (value) {
      this.cnpjNovo = true;
      if (this.validaCnpj(value)) {
        this.cnpjErroMensagem = "CNPJ/CPF já existente";
        if (!this.cnpjNovo) {
          $('#campoCnpj').addClass('ng-invalid');
          $('#campoCnpj-erro').show();
        } else {
          $('#campoCnpj').removeClass('ng-invalid');
          $('#campoCnpj-erro').hide();
          if (this._empresa.id) {
            this.empresaService.getDadosCertificado(this._empresa.id).subscribe({
              next: data => {
                if (!this._empresa.certificado) {
                  this._empresa.certificado = new CertificadoEmpresa();
                }
                this._empresa.certificado.validoAPartirDe = data.validoAPartirDe;
                this._empresa.certificado.validoAte = data.validoAte;
              }, error: error => {
                if (error.status == HttpStatusCode.Unauthorized) {
                  this.cnpjErroMensagem = "CNPJ pertence a outro grupo"
                  $('#campoCnpj').addClass('ng-invalid');
                  $('#campoCnpj-erro').show();
                }
              }
            });
          }
        }
      } else {
        this.cnpjErroMensagem = "CNPJ inválido"
        $('#campoCnpj').addClass('ng-invalid');
        $('#campoCnpj-erro').show();
      }
    }
  }

  onBlurEmail(event: any) {
    var value: String = event.target.value;
    if (value) {
      if (this.validaEmail(value)) {
        $('#campoEmail').removeClass('ng-invalid');
        $('#campoEmail-erro').hide();
      } else {
        $('#campoEmail').addClass('ng-invalid');
        $('#campoEmail-erro').show();
      }
    }
  }

  validaCnpj(value: String): boolean {
    if (value) {
      const Joi = require('@hapi/joi').extend(validator);
      const cnpjSchema = Joi.document().cnpj();
      value = (<String> value).replace(/[^a-zA-Z0-9 ]/g, "")
      var validate = cnpjSchema.validate(value);
      this.cnpjValido = (validate.error == undefined);
      return this.cnpjValido;
    } else {
      return this.cnpjValido = true;
    }
  }

  validaCnpjPrestador(value: string): boolean {
    return this.validaCnpj(value);
  }

  private validaEmail(value: String): boolean {
    const pattern = /.+@.+\..+/;
    return (value.length >= 5 && value.length <= 250 && pattern.test(value.toString()));
  }

  clickSalvar() {
    if (this._empresa.modulos.nfseModulo.nfseEnable && this._empresa.modulos.nfseModulo.nfseMunicipios.length == 0) {
      this.showMensagemAlerta("Campos obrigatórios", "Precisa selecionar pelo menos um município.");
      this.selectTabByName("nfse");
    } else {
      this._empresaAux = this.clone(this._empresa);
      this.onClickSalvar.emit(this._empresa);
      this.globalEventService.savedEmpresaEvent.emit(this._empresa);
      this.close();
    }
  }

  private selectTabByName(tabName: string) {
    const tabs = this.tabView.tabs.map(tab => tab.header);
    this.selectedTab = tabs.indexOf(tabName);
    if (this.selectedTab === -1) {
      console.error('Aba não encontrada:', tabName);
    }
  }

  activeButtonSalvar() {
    return !(
      (this._empresa.id != null && this.validaCnpj(this._empresa.id)) &&
      (this._empresa.codigoUf != null) &&
      (this._empresa.nome != null && this._empresa.nome != '') &&
      !(this.compareObjects(this._empresa, this._empresaAux))
    );
  }

  private showMensagemSuccess(mensagem: string, detalhe: string) {
    this.messageService.add({
      key: 'toastTopCenter800px',
      severity: 'success',
      summary: '' + mensagem,
      detail: '' + detalhe,
      life: 5000
    });
  }

  private showMensagemInfo(mensagem: string, detalhe: string) {
    this.messageService.add({
      key: 'toastTopCenter800px',
      severity: 'info',
      summary: '' + mensagem,
      detail: '' + detalhe,
      life: 5000
    });
  }

  private showMensagemErro(mensagem: string, detalhe: string) {
    this.messageService.add({
      key: 'toastTopCenter800px',
      severity: 'error',
      summary: '' + mensagem,
      detail: '' + detalhe,
      life: 10000
    });
  }

  private showMensagemAlerta(mensagem: string, detalhe: string) {
    this.messageService.add({
      key: 'toastTopCenter800px',
      severity: 'warn',
      summary: '' + mensagem,
      detail: '' + detalhe,
      life: 10000
    });
  }

  addNovoPrestador() {
    this.prestadores = [new Prestador(), ...this.prestadores];
  }

  removePrestador(prestador: Prestador) {
    const index = this.prestadores.findIndex(p => p == prestador);
    if (index > -1) {
      this.prestadores.splice(index, 1);
    }
  }

  private limparErros() {
    $('#campoCnpj-erro').hide();
    $('#campoCnpj').removeClass('ng-invalid');
    $('#campoEmail-erro').hide();
    $('#campoEmail').removeClass('ng-invalid');
  }

  close() {
    this.onClickCancelar.emit();
  }

  private clone(obj: any): any {
    try {
        return JSON.parse(JSON.stringify(obj));
      } catch (error) {
        console.error('Error parsing object to JSON:', error);
        return obj;
      }
    }

  private compareObjects(obj1: Object, obj2: Object) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }
}
