import { OnChanges } from '@angular/core';
import { GlobalEventService } from 'src/shared/global-event.service.ts.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MunicipiosNfseService } from '../../../../services/municipios-nfse.service';
import { saveAs } from 'file-saver';
import { NFSeDownloadRequest } from './../../../../model/nfse-download.request';
import { NFSeConsultaWsRequest } from '../../../../model/nfse-consulta-ws.request';
import { NFSeService } from '../../../../services/nfse.service';
import { Table } from 'primeng/table';
import { NFSePagedRequest } from '../../../../model/nfse-paged-request.model';
import { LazyLoadEvent, MenuItem, MessageService } from 'primeng/api';
import { Page } from 'src/model/page.model';
import { NFSeResponse } from './../../../../model/nfse-response.model';
import { NFSeTipo } from 'src/model/enums/nfse-tipo.enum';
import { MensagemService } from 'src/services/mensagem.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DadosMunicipioNfseModel } from 'src/model/dados-municipio-nfse.model';
import { Periodo } from 'src/model/periodo.model';
import { MainComponent } from '../main.component';
import { Empresa } from 'src/model/empresa.model';
import { AuthService } from 'src/services/auth.service';
import { NotificacoesService } from 'src/services/notificacoes.service';
import moment from 'moment';
import { DownloadTipos } from 'src/model/enums/download-tipos.enum';
import { ClienteTipo } from 'src/model/enums/cliente-tipo.enum';

@Component({
  selector: 'app-nfse',
  templateUrl: './nfse.component.html',
  styleUrls: ['./nfse.component.scss']
})
export class NfseComponent implements OnInit, OnDestroy {

  @ViewChild('dt') table!: Table;

  loading: boolean = false;
  timeout: any;

  contextOptions: any[] = [
    { icon: 'pi pi-download', label: "Tomadas", value: NFSeTipo.TOMADA, disabled : false },
    { icon: 'pi pi-upload', label: "Prestadas", value: NFSeTipo.PRESTADA, disabled : false },
  ];
  contextSel: any;

  selectedEmpresa?: Empresa;

  clienteTipo = ClienteTipo;
  loginClienteTipo = this.authService.getUsuario()?.clienteTipo;

  todosMunicipios: DadosMunicipioNfseModel[] = [];
  municipiosContratados: DadosMunicipioNfseModel[] = [];
  selectedMunicipio?: DadosMunicipioNfseModel;
  somaTotalNotasAtivas: number = 0;
  somaTotalNotasCanceladas: number = 0;

  // Context Menu - Linha da GridagedRequest
  selectedNFSeContextMenu?: NFSeResponse;
  itemsContextMenu: MenuItem[] = [];

  nfseRequest: NFSePagedRequest = new NFSePagedRequest();
  pageSizes = [10, 15, 20, 50, 100];
  nfseResponse: Page<NFSeResponse> = new Page<NFSeResponse>();
  selectedRows: NFSeResponse[] = [];
  periodo: Periodo | undefined;

  checkedSelectionAll: boolean = false;

  nfseConsultaWs: NFSeConsultaWsRequest = new NFSeConsultaWsRequest();

  downloadTipos = DownloadTipos;

  summaryModalSelecaoDatas: string = "Busca novas notas por data de emissão";
  showModalSelecaoDatas = false;
  rangeDates: Date[] | undefined;
  minDate: Date | undefined;
  maxDate: Date | undefined;
  maximoIntervaloDeDias = 7;

  subscriptionEmpresaChange: any;
  subscriptionPeriodoChange: any;
  subscriptionNotificacoes: any;

  isEnableNfsePage = true;

  textSizeToTruncate = 60;
  tooltipOptionsTextTruncate = {
    showDelay: 250,
    autoHide: false
  };

  constructor(
    private mensagemService: MensagemService,
    private nfseService: NFSeService,
    private municipiosNfseService: MunicipiosNfseService,
    private domSanitizer: DomSanitizer,
    public main: MainComponent,
    private messageService: MessageService,
    public authService: AuthService,
    private notificacoesService: NotificacoesService,
    private globalEventService: GlobalEventService
  ) {
    this.base64Pdf = this.domSanitizer.bypassSecurityTrustResourceUrl('');
  }

  ngOnInit(): void {
    this.contextSel = localStorage.getItem('contextSel') ? JSON.parse(localStorage.getItem('contextSel') || 'null') : this.contextOptions[0];
    this.nfseResponse.totalElements = 0;

    this.subscriptionNotificacoes = this.notificacoesService.onReceiveNovasNotas.subscribe((modulo: string) => {
      if (modulo == "NFS-e") {
        this.refresh();
      }
    });

    // Carrega todos os municipios para NFSe implementados no sistema
    this.showModalLoading();
    this.municipiosNfseService.getMunicipios().then(muns => {
      this.todosMunicipios = muns;

      if (this.main.selectedEmpresa) {
        this.selectedEmpresa = this.main.selectedEmpresa;
        this.onChangeEmpresa();
      }

      this.subscriptionEmpresaChange = this.main.empresaChange.subscribe((empresa: Empresa) => {
        this.selectedEmpresa = empresa;
        this.onChangeEmpresa();
        this.isEnableNfsePage = empresa.modulos.nfseModulo.nfseEnable;
      });

      this.subscriptionPeriodoChange = this.main.periodoChange.subscribe(() => {
        setTimeout(() => {
          this.buscaNoBancoDadosNFSe();
        });
      });

      this.hideModalLoading();
    });

    this.globalEventService.nfseOpenPageEvent.emit(true);
  }

  ngOnDestroy(): void {
    this.subscriptionEmpresaChange?.unsubscribe();
    this.subscriptionPeriodoChange?.unsubscribe();
    this.subscriptionNotificacoes?.unsubscribe();
    this.globalEventService.nfseOpenPageEvent.emit(false);
  }

  onChangeContextOptions(event: any) {
    if (event.value == null) {
      setTimeout(() => {
        if (this.nfseRequest.context == undefined) {
          this.contextSel = this.contextOptions[0];
        } else {
          this.contextSel = this.contextOptions.find((t) => t.value == this.nfseRequest.context);
        }
      }, 10);
    } else {
      localStorage.setItem('contextSel', JSON.stringify(event.value));
      //this.nfseRequest.context = event.value.value;
      this.buscaNoBancoDadosNFSe();
    }
  }

  private onChangeEmpresa() {
    this.selectedRows = [];
    this.nfseRequest.pageNum = 0;
    this.carregaCampoMunicipiosContratados();
    setTimeout(() => {
      this.buscaNoBancoDadosNFSe();
    }, 10);
  }

  private carregaCampoMunicipiosContratados() {
    this.municipiosContratados = [];
    if (this.selectedEmpresa && this.selectedEmpresa.modulos.nfseModulo.nfseMunicipios) {
      this.selectedEmpresa.modulos.nfseModulo.nfseMunicipios.forEach(dadosMun => {
        const mun = this.todosMunicipios.find(mun => mun.codigo == dadosMun.codigoMun);
        if (mun) {
          const nfseMun = this.selectedEmpresa?.modulos.nfseModulo.nfseMunicipios.find(nfseMun => nfseMun.codigoMun == mun.codigo);
          mun.temPrestada = nfseMun?.temPrestada;
          mun.temTomada = nfseMun?.temTomada;
          this.municipiosContratados.push(mun);
        }
      });
      setTimeout(() => {
        const municipio = JSON.parse(localStorage.getItem('selectedMunicipio') || 'null');
        var index = this.municipiosContratados.findIndex(m => m.codigo == municipio?.codigo);
        if (index > -1) {
          this.selectedMunicipio = this.municipiosContratados[index];
        } else {
          this.selectedMunicipio = this.municipiosContratados[0];
        }
        this.nfseRequest.codigoMun = this.selectedMunicipio?.codigo;
        this.atualizaContextOptions(this.selectedMunicipio);
      });
    }
  }

  public atualizaContextOptions(selectedMunicipio: DadosMunicipioNfseModel | undefined) {
    let contemDisabilitado = false;
    this.contextOptions.forEach(context => {
      if (context.value == NFSeTipo.PRESTADA) {
        context.disabled = !selectedMunicipio?.temPrestada;
      } else {
        context.disabled = !selectedMunicipio?.temTomada;
      }
      if (context.disabled) {
        contemDisabilitado = true;
      }
    });
    if (contemDisabilitado) {
      this.contextSel = this.contextOptions.find(context => !context.disabled);
    }

    this.itemsContextMenu = [
        {label: 'Visualizar nota', icon: 'pi pi-fw pi-eye', command: () => {
            this.openModalDanfse(this.nfseRequest.codigoMun!, this.selectedNFSeContextMenu?.id!, this.selectedNFSeContextMenu?.numero!)
        }},
        {label: 'Baixar XML', icon: 'pi pi-fw pi-download', command: () => { this.downloadUnicoXML(this.selectedNFSeContextMenu!, DownloadTipos.PADRAO) }}
    ]

    if (selectedMunicipio?.converteParaXmlAbrasf) {
        this.itemsContextMenu.push(
            {
                label: 'Baixar XML ABRASF',
                icon: 'pi pi-fw pi-download',
                command: () => { this.downloadUnicoXML(this.selectedNFSeContextMenu!, DownloadTipos.ABRASF) }
            }
        )
    }

    if (this.selectedNFSeContextMenu?.status == "cancelada" ) {
        this.itemsContextMenu.push(
          {
                label: 'Evento Cancelamento ERP',
                icon: 'pi pi-fw pi-download',
                command: () => { this.downloadUnicoXML(this.selectedNFSeContextMenu!, DownloadTipos.EVENTO_CANCELAMENTO_ERP) }
          }
        )
    }

    this.contextOptions = [...this.contextOptions]; // isso força a atualização do componente na tela
  }

  onLazyLoad(event: LazyLoadEvent) {
    if (this.nfseResponse.totalElements > 0) {
      this.nfseRequest.pageNum = event.first! / event.rows!;
      if (event.rows) {
        this.nfseRequest.pageSize = event.rows;
      }
      this.buscaNoBancoDadosNFSe();
    }
  }

  onChangeMunicipio(event: any) {
    localStorage.setItem('selectedMunicipio', JSON.stringify(event.value));
    this.nfseRequest.codigoMun = event.value.codigo;
    this.atualizaContextOptions(this.selectedMunicipio);
    this.buscaNoBancoDadosNFSe();
    console.log('Código do município selecionado:', this.selectedMunicipio?.codigo);
  }

  refresh() {
    this.buscaNoBancoDadosNFSe();
  }

  private buscaNoBancoDadosNFSe() {
    if (this.selectedEmpresa && this.nfseRequest.codigoMun) {
      if (this.contextSel.value == NFSeTipo.TOMADA) {
        this.nfseRequest.cnpjCpfTomador = this.selectedEmpresa?.id;
        this.nfseRequest.cnpjCpfPrestador = undefined;
      } else {
        this.nfseRequest.cnpjCpfPrestador = this.selectedEmpresa?.id;
        this.nfseRequest.cnpjCpfTomador = undefined;
      }
      //this.nfseRequest.context = this.contextSel.value;
      this.nfseRequest.dataInicio = this.main.periodo.dataInicio;
      this.nfseRequest.dataFim = this.main.periodo.dataFim;
      this.loading = true;
      this.nfseService.getNotasFiscaisRecebidas(this.nfseRequest).subscribe({
        next: data => {
          this.nfseResponse = data;
          this.calcularSomaTotalNotas();
        },
        error: e => {
          var message = "Ocorreu um erro ao consultar as notas.";
          if (e.erro) {
            message = e.error.message;
          } else if (e.message) {
            message = e.message;
          } else if (e.status == 0) {
            message = "Serviço " + e.url.split("?")[0] + " está offline";
          } else if (e.message) {
            message = e.message;
          }
          this.mensagemService.showMensagemErro("Erro", message);
        }
      }).add(() => {
        this.loading = false;
      });
    }
  }

  calcularSomaTotalNotas(): void {
    if (this.selectedMunicipio?.codigo === 3505708) {
      this.somaTotalNotasAtivas = this.nfseResponse.content.reduce((total, nfse) => {
        return total + (nfse.status === 'ativa' && typeof nfse.valor === 'number' ? nfse.valor : 0);
      }, 0);
      this.somaTotalNotasCanceladas = this.nfseResponse.content.reduce((total, nfse) => {
        return total + (nfse.status === 'cancelada' && typeof nfse.valor === 'number' ? nfse.valor : 0);
      }, 0);
    } else {
      this.somaTotalNotasAtivas = 0;
      this.somaTotalNotasCanceladas = 0;
    }
  }

  private getDataRetroaticaEmpresaSelecionada() {
    return this.selectedEmpresa?.modulos.nfseModulo.dataRetroativaNFSe! ?
      this.selectedEmpresa?.modulos.nfseModulo.dataRetroativaNFSe! : this.selectedEmpresa?.dataRegistro;
  }

  openModalBuscarNovasNotas() {
    this.showModalSelecaoDatas = true;

    const dataRetroativa = moment(this.getDataRetroaticaEmpresaSelecionada());
    dataRetroativa.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

    const dataDePeriodoSel = this.main.periodo.dataInicio;

    let dataDe = new Date();
    let dataAte = new Date();
    dataDe.setHours(12, 0, 0);
    dataAte.setHours(12, 0, 0);

    if (dataDe.getMonth() == dataDePeriodoSel?.getMonth()) {
      if (dataDe.getDate() > 1) {
        dataDe.setDate(dataDe.getDate() - 1);
      }
    } else {
      if (dataDePeriodoSel?.getMonth() == dataRetroativa.toDate().getMonth()) {
        dataDe.setDate(dataRetroativa.toDate().getDate());
      } else {
        dataDe.setDate(1);
      }
      dataDe.setFullYear(dataDePeriodoSel!.getFullYear(), dataDePeriodoSel!.getMonth(), dataDe.getDate());
      dataAte = moment(dataDe).toDate();
      dataAte.setDate(dataAte.getDate() + 6);
      if (dataAte.getMonth() != dataDe.getMonth()) {
        dataAte.setFullYear(dataDe.getFullYear(), dataDe.getMonth() + 1, 1);
        dataAte.setDate(dataAte.getDate() - 1);
      }
    }

    this.rangeDates = [dataDe, dataAte];

    let hoje = new Date();
    hoje.setHours(12, 0, 0);

    this.minDate = dataRetroativa.toDate();
    this.maxDate = hoje;
  }

  handleRangeDates() {
    const maxDias = this.maximoIntervaloDeDias - 1;
    if (this.rangeDates![0] && !this.rangeDates![1]) {
      this.maxDate = new Date(this.rangeDates![0].getFullYear(), this.rangeDates![0].getMonth(), this.rangeDates![0].getDay() + 1);
      this.maxDate.setDate(this.rangeDates![0].getDate() + maxDias);
      // Verifica se as datas não estão no mesmo mês
      if (this.rangeDates![0].getMonth() !== this.maxDate.getMonth() || this.rangeDates![0].getFullYear() !== this.maxDate.getFullYear()) {
        const lastDayOfMonth = new Date(this.rangeDates![0].getFullYear(), this.rangeDates![0].getMonth() + 1, 0);
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        this.maxDate = lastDayOfMonth > today ? today : lastDayOfMonth;
      }
    }
  }

  isDateRangeValid(): boolean {
    return this.rangeDates?.length == 2 && (this.rangeDates[0] == null || this.rangeDates[1] == null);
  }

  sincronizaStatusNotas() {
    const datas: number[] = this.nfseResponse.content.map(nota => new Date(nota.dataEmissao!).getDate());
    const mesAtual = this.main.periodo.dataInicio!;

    const dataInicial = new Date(mesAtual.getFullYear(), mesAtual.getMonth(), Math.min(...datas));
    const dataFinal = new Date(mesAtual.getFullYear(), mesAtual.getMonth(), Math.max(...datas));

    const nfseConsultaWs: NFSeConsultaWsRequest = new NFSeConsultaWsRequest();
    nfseConsultaWs.dataInicio = dataInicial;
    nfseConsultaWs.dataFim = dataFinal;
    nfseConsultaWs.context = this.contextSel.value;
    nfseConsultaWs.clienteLogin = this.authService.getUsuario()?.login;
    nfseConsultaWs.empresaCnpj = this.selectedEmpresa?.id;
    nfseConsultaWs.codigoMun = this.selectedMunicipio?.codigo;
    nfseConsultaWs.isSincronizarStatus = true;

    try {
        this.showModalLoading(true);
        this.nfseService.consultaNotasPorData(nfseConsultaWs).subscribe({
          next: () => {
            this.showMensagemInfo("Requisição enviada", "Aguardar a notificação da conclusão do processamento.");
          },
          error: (e) => {
            this.showMensagemErro("Erro", e.message);
          }
        }).add(() => {
            this.hideModalLoading();
        });
    } catch (error) {
        this.mensagemService.showMensagemErro("Erro", String(error));
    }
  }

  buscaNovasNotasDiasSelecionados() {
    this.nfseConsultaWs.dataInicio = this.rangeDates![0];
    this.nfseConsultaWs.dataFim = this.rangeDates![1];
    this.buscaNovasNotas();
  }

  buscaNovasNotasMesInteiro() {
    this.nfseConsultaWs.dataInicio = this.main.periodo.dataInicio;
    this.nfseConsultaWs.dataFim = this.main.periodo.dataFim;
    this.buscaNovasNotas();
  }

  private buscaNovasNotas() {
    this.closeModalSelecaoDatas();
    this.nfseConsultaWs.context = this.contextSel.value;
    this.nfseConsultaWs.clienteLogin = this.authService.getUsuario()?.login;
    this.nfseConsultaWs.empresaCnpj = this.selectedEmpresa?.id;
    this.nfseConsultaWs.empresaNome = this.selectedEmpresa?.nome;
    this.nfseConsultaWs.codigoMun = this.selectedMunicipio?.codigo;
    let currentDate: Date = new Date();
    this.nfseConsultaWs.dataConsulta = currentDate.toLocaleString();
    try {
      this.showModalLoading(true);
      this.nfseService.consultaNotasPorData(this.nfseConsultaWs).subscribe({
        next: () => {
          this.showMensagemInfo("Requisição enviada", "Aguardar a notificação da conclusão do processamento.");
        },
        error: (e) => {
          this.showMensagemErro("Erro", e.message);
        }
      }).add(() => {
        this.hideModalLoading();
      });
    } catch (error) {
      this.mensagemService.showMensagemErro("Erro", String(error));
    }
  }

  onSelectionChange(event: []) {
    this.selectedRows = event;
    this.refreshSelectAllCheckBox();
  }

  refreshSelectAllCheckBox() {
    this.checkedSelectionAll = false;
    if (this.nfseResponse.content.length > 0) {
      this.checkedSelectionAll = this.nfseResponse.content.every(item => {
        return !(this.selectedRows.find(f => f.id == item.id) == undefined);
      });
    }
  }

  onSelectAllChange(event: {checked: boolean, originalEvent: PointerEvent}) {
    if (event.checked) {
      // Adiciona os itens que estão na pagina atual na lista de itens selecionados
      this.nfseResponse.content.forEach(item => {
        if (this.selectedRows.find(f => f.id == item.id) == undefined) {
          this.selectedRows.push(item);
        }
      });
      this.refreshSelectionRowsCheckBox();
    } else {
      // Remove somente os itens que estão na pagina atual da lista de itens selecionados
      this.nfseResponse.content.forEach(item => {
        this.selectedRows = this.selectedRows.filter(function(itemSelected) {
          return itemSelected.id != item.id;
        });
      });
    }
    setTimeout(() => {
      if (event.originalEvent.target) {
        $(event.originalEvent.target).parent().removeClass("p-checkbox-focused");
        $(event.originalEvent.target).removeClass("p-focus");
      }
    }, 100);
  }

  /* Força a atualização das linhas para exibir os checkbox selecionados pelo checkbox que seleciona tudo */
  refreshSelectionRowsCheckBox() {
    if (this.table) {
      this.table.toggleRowWithCheckbox({}, null);
    }
    this.selectedRows = this.selectedRows.splice(0, this.selectedRows.length - 1);
  }

  downloadNFSeSelecionadas(downloadTipo: DownloadTipos) {
    this.downloadXML(this.selectedRows, downloadTipo);
  }

  downloadUnicoXML(notaFiscal: NFSeResponse, downloadTipo: DownloadTipos) {
    let notasFiscais: NFSeResponse[] = [];
    notasFiscais.push(notaFiscal);
    this.downloadXML(notasFiscais, downloadTipo);
  }

  downloadXML(notasFiscais: NFSeResponse[], downloadTipo: DownloadTipos) {
    var ids: String[] = [];
    for(var notafiscal of notasFiscais) {
      ids.push(notafiscal.id);
    }

    let request = new NFSeDownloadRequest();
    request.ids = ids;
    request.codigoMun = this.selectedMunicipio?.codigo;
    request.downloadTipo = downloadTipo;

    this.nfseService.downloadNotasFiscais(request).subscribe({
      next: data => {
        let filename = data.headers.get('content-disposition')?.split('filename=')[1];

        if (downloadTipo == DownloadTipos.ABRASF) {
            filename = `${filename}`
        }

        if (downloadTipo == DownloadTipos.EVENTO_CANCELAMENTO_ERP) {
          filename = `${filename}`
        }

      if (data.body) {
        saveAs(data.body, filename);
      }
      },
      error: e => {
        this.mensagemService.showMensagemErro("Erro", e.error);
      }
    });
  }

  private showMensagemInfo(mensagem: string, detalhe: string) {
    this.messageService.add({
      key: 'toastBottomRigth800px',
      severity: 'info',
      summary: '' + mensagem,
      detail: '' + detalhe,
      life: 5000
    });
  }

  private showMensagemErro(mensagem: string, detalhe: string) {
    this.messageService.add({
      key: 'toastBottomRigth800px',
      severity: 'error',
      summary: '' + mensagem,
      detail: '' + detalhe,
      life: 10000
    });
  }

  openModalSelecaoDatas() {
    this.showModalSelecaoDatas = true;
  }

  closeModalSelecaoDatas() {
    this.showModalSelecaoDatas = false;
  }

  private showModalLoading(exibiImediatamente?: boolean) {
    this.loading = this.main.showModalLoading(exibiImediatamente);
  }

  private hideModalLoading() {
    this.loading = this.main.hideModalLoading();
  }

  removeHighlightContextmenu() {
    $(".p-highlight-contextmenu").removeClass("p-highlight-contextmenu");
  }

  showModalDanfse = false;
  showProgressSpinModalDanfse = true;
  base64Pdf?: any;

  openModalDanfse(codMun: Number, id: String, numNf: Number) {
    var requisitante = this.nfseRequest.cnpjCpfTomador ? this.nfseRequest.cnpjCpfTomador : this.nfseRequest.cnpjCpfPrestador;
    this.showModalDanfse = true;
    this.showProgressSpinModalDanfse = true;
    this.base64Pdf = this.domSanitizer.bypassSecurityTrustResourceUrl(this.nfseService.generateUrlGetDanfsePdf(codMun, id, requisitante + "_" + numNf));
    setTimeout(() => {
      document.getElementById("pdfViewer")!.onload = () => {
        this.showProgressSpinModalDanfse = false;
      };
    });
  }

  closeModalDanfse() {
    ($('#modal-danfe') as any).modal('hide');
  }
}

