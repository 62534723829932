import { DadosEmissaoNf } from './dados-emissao-nf.model';
import { Modulos } from './modulos';
import { CertificadoEmpresa } from './certificado-empresa.model';
import { Endereco } from './endereco.model';

export class Empresa {

  id?: string;
  suporteLogin?: string;
  ativa?: boolean;
  dataRegistro?: Date;
  nome?: string;
  codigoUf?: number;
  modulos: Modulos = new Modulos();
  certificado?: CertificadoEmpresa;
  endereco: Endereco = new Endereco();
  dadosEmissaoNf: DadosEmissaoNf = new DadosEmissaoNf();
}
