<div *ngIf="isEnablePortalNacionalPage; else notEnabledPortalNacionalPage">

  <p-contextMenu [style]="{'width': '200px'}" #cm [model]="itemsContextMenu" (onHide)="removeHighlightContextmenu()"></p-contextMenu>

  <div class="container">
    <div class="container-width">
      <!-- TITULO -->
      <div class="d-flex flex-row align-items-baseline" style="margin-top: -10px">
        <div class="flex-grow-1">
          <div style="width: 200px">
            <h4 style="line-height: 2">NFS-e Nacional NFS-e</h4>
          </div>
        </div>
        <div style="margin-top: -10px">
          <button pButton class="p-button-rounded p-button-text me-5 ms-4" pTooltip="Recarrega dados da tabela"
            [showDelay]="500" (click)="refresh()">
            <i class="pi pi-refresh"></i>
          </button>
        </div>
      </div>

      <!-- Botões de ações -->
      <div class="d-flex mt-4">
        <div class="flex-grow-1">
          <div class="d-flex flex-row">
            <div>
              <p-inputGroup>
                <span class="p-float-label">
                  <input id="filtroChaveAcessoNfe" pInputText style="width: 370px"
                    [(ngModel)]="portalRequest.chaveAcesso" />
                  <label for="filtroChaveAcessoNfe">Chave de acesso</label>
                </span>
                <button type="button" pButton icon="pi pi-times"
                  class="p-button-outlined p-button-secondary button-grouped"
                  (click)="limpaFiltro('chaveAcesso')"></button>
              </p-inputGroup>
            </div>
            <div>
              <div>
                <p-button icon="pi pi-search" [rounded]="true" [text]="true" [raised]="true" severity="info"
                  class="ms-4" (onClick)="onClickFiltrarChaveAcesso()"></p-button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div class="d-flex">
              <div class="margin-r-3">
                <button pButton type="button" icon="pi pi-cloud-download" label="Busca novas notas"
                  class="p-button-outlined botao-custom" (click)="consultaPortalNacionalNfse()"
                  [disabled]="selectedEmpresa == undefined"></button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="d-flex">
            <div class="me-2">
              <button pButton type="button" class="p-button-rounded p-button-secondary"
                pTooltip="Download das notas selecionadas" tooltipPosition="top" icon="pi pi-download"
                (click)="downloadPortalNotasSelecionadas()" [disabled]="selectedRows.length == 0"></button>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-3">
        <!-- Tabela com dados -->
        <p-table #dt class="table-nfse" [value]="portalPage.content" [lazy]="true" (onLazyLoad)="lazyLoad($event)"
          [totalRecords]="portalPage.totalElements" [(selection)]="selectedRows" dataKey="chaveAcesso"
          [showCurrentPageReport]="true" [rowsPerPageOptions]="pageSizes" [rows]="pageSizes[0]" [loading]="loading"
          [paginator]="true" currentPageReportTemplate="{first} {{ 'até' }} {last} {{ 'de' }} {{
            portalPage.totalElements
          }} {{ 'entradas' }}" [scrollable]="true" scrollHeight="calc(100% - 200px)" [contextMenu]="cm"
          [(contextMenuSelection)]="selectedNotaContextMenu">
          <!-- HEADER -->
          <ng-template pTemplate="header">
            <tr>
              <th [style]="{ width: '50px' }">
                <div style="padding-left: 5px; padding-top: 5px; cursor: pointer"
                  pTooltip="Seleciona os itens da página" tooltipPosition="left" [showDelay]="1000" [hideDelay]="300"
                  (click)="onClickSelectedAllRows()">
                  <i class="bi bi-check-all" style="font-size: 1.5rem; color: #999999"></i>
                </div>
              </th>
              <th pSortableColumn="situacao" style="min-width: 100px">
                Status
                <p-sortIcon field="situacao"></p-sortIcon>
              </th>
              <th pSortableColumn="chaveAcesso" style="min-width: 200px">
                Chave de acesso
                <p-sortIcon field="chaveAcesso"></p-sortIcon>
              </th>
              <th pSortableColumn="cnpjCpfEmitente" style="min-width: 200px">
                Prestador
                <p-sortIcon field="cnpjCpfEmitente"></p-sortIcon>
              </th>
              <th pSortableColumn="municipio" style="min-width: 175px">
                Município
                <p-sortIcon field="municipio"></p-sortIcon>
              </th>
              <th pSortableColumn="nsu" style="min-width: 100px">
                NSU
                <p-sortIcon field="nsu"></p-sortIcon>
              </th>
              <th pSortableColumn="dataEmissao" style="min-width: 200px">
                Data de emissão
                <p-sortIcon field="dataEmissao"></p-sortIcon>
              </th>
              <th pSortableColumn="origem" [style]="{ width: '125px', 'text-align': 'center' }">
                Origem
                <p-sortIcon field="origem"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <!-- BODY -->
          <ng-template pTemplate="body" let-nfse>
            <tr [pContextMenuRow]="nfse" class="table-row-body" [style]="{ 'font-size': '12px' }">
              <td>
                <p-tableCheckbox [value]="nfse" class="ml-1"></p-tableCheckbox>
              </td>
              <td>
                <span [ngClass]="{'nfse-ativa': nfse.situacao == 'ativa', 'nfse-cancelada': nfse.situacao == 'cancelada'}">
                  {{ nfse.situacao }}
                </span>
              </td>
              <td>{{ nfse.chaveAcesso }}</td>
              <td>{{ nfse.cnpjCpfEmitente }}</td>
              <td>{{ nfse.municipio }}</td>
              <td>{{ nfse.nsu }}</td>
              <td>
                {{ nfse.dataEmissao | date : "dd/MM/yyyy" }}&nbsp;<span
                  style="color: rgb(165, 165, 165); font-size: 12px">{{ nfse.dataEmissao | date : "HH:mm:ss" }}</span>
              </td>
              <td>{{ nfse.origem }}</td>
            </tr>
          </ng-template>
        </p-table>
        <div *ngIf="portalPage.totalElements == 0" class="mt-3 w-100 d-flex justify-content-center">
          <div>Sem dados</div>
        </div>
      </div>
      <div *ngIf="portalPage.totalElements > 0">
        <b>Selecionadas:</b> {{ selectedRows.length }}
      </div>
    </div>
  </div>
</div>

<ng-template #notEnabledPortalNacionalPage>
  <div class="centered-container">
    <div class="centered-content">
      <img src="assets/images/danger.png" alt="Danger" />
      <p class="titulo-nao-habilitado">
        Empresa não cadastrada no módulo NFS-e Nacional
      </p>
    </div>
  </div>
</ng-template>

<p-toast position="bottom-center"></p-toast>

<!-- Modal DANFSe -->
<p-dialog
  header="DANFSe"
  [modal]="false"
  [(visible)]="showModalDanfse"
  [breakpoints]="{ '960px': '75vw' }" [style]="{ width: '85vw', height: '90vw' }"
  [draggable]="false"
  [resizable]="false">
  <!-- Se houver erro, exibe a mensagem -->
  <div *ngIf="erroDanfse != null" class="text-center error-container">
    <p class="text-danger"><strong>{{ erroDanfse }}</strong></p>
  </div>
  <!-- Se não houver erro, exibe o PDF -->
  <div *ngIf="erroDanfse == null" [ngClass]="{ 'h-100': !showProgressSpinModalDanfse, 'd-none': showProgressSpinModalDanfse }">
    <iframe *ngIf="!erroDanfse" id="pdfViewer" [src]="base64Pdf" width="100%" height="100%"></iframe>
  </div>
  <!-- Spinner enquanto carrega -->
  <div class="d-flex align-items-center justify-content-center h-100" *ngIf="showProgressSpinModalDanfse">
    <p-progressSpinner></p-progressSpinner>
  </div>
</p-dialog>
