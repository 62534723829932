export class DadosEmissaoNf {

  incentivadorCultural?: boolean;
  incentivoFiscal?: boolean;
  simplesNacional?: boolean;
  inscricaoMunicipal?: string;
  certificado?: string;
  razaoSocial?: string;
  regimeTributario?: number;
  regimeTributarioEspecial?: number;
}
