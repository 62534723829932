<div class="me-4">

  <p-tabView
    *ngIf="_cliente.clienteTipo == ClienteTipo.SUPORTE"
    #tabView [styleClass]="'width: 700px'"
    [(activeIndex)]="selectedTab">
    <!-- TabView - Dados do _cliente -->
    <p-tabPanel>
      <ng-template pTemplate="header" style="display: none !important">
        Dados do cliente
      </ng-template>
      <div class="m-4" [ngStyle]="{'display': loading ? 'none' : 'block'}">
        <div class="d-flex flex-row">
          <div>

            <div [ngStyle]="{'display': !exibeCampoAtivo ? 'none' : 'block'}" class="field-checkbox mb-4">
              <p-checkbox
                inputId="ativo"
                [(ngModel)]="_cliente.ativo"
                [binary]="true">
              </p-checkbox>
              <label for="ativa" class="ms-2">Ativo</label>
            </div>

            <div class="d-flex flex-row">
              <div class="d-flex flex-row align-items-center">
                <div class="field" style="width: 300px;">
                  <label class="d-flex justify-content-between">
                    <div>Login</div>
                    <div>
                      <small id="campoLogin-erro" class="p-error block">{{loginErroMensagem}}</small>
                    </div>
                  </label>
                  <input pInputText
                    id="campoLogin"
                    type="text"
                    maxlength="25"
                    (blur)="onBlurLogin($event)"
                    [(ngModel)]="_cliente.login"
                    [ngModelOptions]="{standalone: true}"
                    [disabled]="desabilitaCampoLogin || somenteVisualizar"
                    autocomplete="off">
                </div>
              </div>
              <div *ngIf="validandoLogin" class="d-flex align-items-end ms-2" style="padding-bottom: 10px;">
                <p-progressSpinner class="progress-validando-login" ariaLabel="loading"></p-progressSpinner>
              </div>
            </div>

            <div class="d-flex flex-row align-items-center">
              <div class="field" style="width: 500px;">
                <label class="d-flex justify-content-between">
                  <div>Nome</div>
                  <div><small id="campoNome-erro" class="p-error block">Campo obrigatório</small></div>
                </label>
                <input pInputText
                  id="campoNome"
                  maxlength="70"
                  [(ngModel)]="_cliente.nome"
                  (blur)="onBlurNome($event)"
                  [ngModelOptions]="{standalone: true}">
              </div>
            </div>

            <div class="d-flex flex-row align-items-center">
              <div class="field" style="width: 500px;">
                <label class="d-flex justify-content-between">
                  <div>E-mail</div>
                  <div><small id="campoEmail-erro" class="p-error block">{{emailErroMensagem}}</small></div>
                </label>
                <input pInputText
                  id="campoEmail"
                  type="text"
                  (blur)="onBlurEmail($event)"
                  [(ngModel)]="_cliente.email"
                  [ngModelOptions]="{standalone: true}">
              </div>
            </div>

            <div *ngIf="desabilitaCampoLogin" class="mt-4">
              <b>Data do cadastro: &nbsp;</b>
              <span>{{_cliente.dataRegistro | date : "  dd/MM/yyyy"}}</span>
              <span style="font-size: 12px; color: gray;">{{_cliente.dataRegistro | date : "  HH:mm"}}</span>
            </div>

          </div>
        </div>
      </div>
    </p-tabPanel>
    <!-- TabView - Controle download XML -->
    <p-tabPanel>
      <ng-template pTemplate="header">
        Controle download XML
      </ng-template>
      <div class="m-4">
        <div class="coluna-3">
          <div class="d-flex flex-row">
            <div>
              <div class="mt-3 mb-3">
                <div class="field mt-3">
                  <div class="field">
                    <label for="integeronly">
                      Quantidade XML/mês
                    </label>
                    <p-inputNumber inputId="integeronly" [(ngModel)]="_cliente.controleDownloadXML.limite"></p-inputNumber>
                  </div>
                </div>
                <div class="field-checkbox mb-4 mt-4">
                  <p-checkbox
                    [(ngModel)]="_cliente.controleDownloadXML.bloquearQuandoLimiteAtingir"
                    [binary]="true"
                    inputId="bloqueioDownloadXml">
                  </p-checkbox>
                  <label for="bloqueioDownloadXml" class="ms-3" pTooltip="Se desmarcado será cobrado um valor por XML que exceder a quantidade XML/mês">
                    Bloquear download de XML quando atingir quantidade XML/mês
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </p-tabPanel>
    <!-- TabView - e-Reobot Desktop -->
    <p-tabPanel>
      <ng-template pTemplate="header">
        e-Reobot Desktop
      </ng-template>
      <div class="m-4">
        <div class="coluna-3">
          <div class="d-flex flex-row">
            <div>
              <div class="mt-3 mb-3">
                <div class="field-checkbox mb-4">
                  <p-checkbox
                    [(ngModel)]="_cliente.ereobotDesktopModulo.ereobotDesktopEnable"
                    [binary]="true"
                    inputId="enablePortalNacional"
                    [disabled]="somenteVisualizar">
                  </p-checkbox>
                  <label for="enablePortalNacional" class="ms-3">Habilita e-Reobot Desktop</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>

  <div class="ms-3" *ngIf="_cliente.clienteTipo == ClienteTipo.CLIENTE">
    <div [ngStyle]="{'display': !exibeCampoAtivo ? 'none' : 'block'}" class="field-checkbox mb-4">
      <p-checkbox
        inputId="ativo"
        [(ngModel)]="_cliente.ativo"
        [binary]="true">
      </p-checkbox>
      <label for="ativa" class="ms-2">Ativo</label>
    </div>

    <div class="d-flex flex-row">
      <div class="d-flex flex-row align-items-center">
        <div class="field" style="width: 300px;">
          <label class="d-flex justify-content-between">
            <div>Login</div>
            <div>
              <small id="campoLogin-erro" class="p-error block">{{loginErroMensagem}}</small>
            </div>
          </label>
          <input pInputText
            id="campoLogin"
            type="text"
            maxlength="25"
            (blur)="onBlurLogin($event)"
            [(ngModel)]="_cliente.login"
            [ngModelOptions]="{standalone: true}"
            [disabled]="desabilitaCampoLogin || somenteVisualizar"
            autocomplete="off">
        </div>
      </div>
      <div *ngIf="validandoLogin" class="d-flex align-items-end ms-2" style="padding-bottom: 10px;">
        <p-progressSpinner class="progress-validando-login" ariaLabel="loading"></p-progressSpinner>
      </div>
    </div>

    <div class="d-flex flex-row align-items-center">
      <div class="field" style="width: 500px;">
        <label class="d-flex justify-content-between">
          <div>Nome</div>
          <div><small id="campoNome-erro" class="p-error block">Campo obrigatório</small></div>
        </label>
        <input pInputText
          id="campoNome"
          maxlength="70"
          [(ngModel)]="_cliente.nome"
          (blur)="onBlurNome($event)"
          [ngModelOptions]="{standalone: true}"
          [disabled]="somenteVisualizar">
      </div>
    </div>

    <div class="d-flex flex-row align-items-center">
      <div class="field" style="width: 500px;">
        <label class="d-flex justify-content-between">
          <div>E-mail</div>
          <div><small id="campoEmail-erro" class="p-error block">{{emailErroMensagem}}</small></div>
        </label>
        <input pInputText
          id="campoEmail"
          type="text"
          (blur)="onBlurEmail($event)"
          [(ngModel)]="_cliente.email"
          [ngModelOptions]="{standalone: true}"
          [disabled]="somenteVisualizar">
      </div>
    </div>

    <div *ngIf="desabilitaCampoLogin" class="mt-4">
      <b>Data do cadastro: &nbsp;</b>
      <span>{{_cliente.dataRegistro | date : "  dd/MM/yyyy"}}</span>
      <span style="font-size: 12px; color: gray;">{{_cliente.dataRegistro | date : "  HH:mm"}}</span>
    </div>
    <div *ngIf="!desabilitaCampoLogin" class="mt-4" style="height: 20px;"></div>
  </div>

  <div
    class="w-100 d-flex justify-content-end"
    [ngStyle]="{'margin-top': (_cliente.clienteTipo == ClienteTipo.CLIENTE) ? '30px' : '-60px'}">
    <div class="ms-4">
      <button
        pButton
        (click)="clickSalvar()"
        icon="pi pi-save"
        label="Salvar"
        class="p-button-primary botao-custom"
        *ngIf="!somenteVisualizar"
        [disabled]="activeButtonSalvar() || somenteVisualizar">
      </button>
    </div>
    <div *ngIf="showBotaoCancelar" class="ms-4">
      <button pButton (click)="clickCancelar()" label="Cancelar" class="p-button-text ms-3"></button>
    </div>
  </div>
</div>
