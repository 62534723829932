export class Endereco {

  logradouro?: string;
  numero?: string;
  complemento?: string;
  bairro?: string;
  codigoCidade?: string;
  descricaoCidade?: string;
  estado?: string;
  cep?: string;
}
